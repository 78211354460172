import React, { useEffect, useState } from 'react'
import MenuLateral from '../../Components/MenuLateral'
import Rodape from '../../Components/Rodape'
import { Alert, Card,  CardContent, Container,  Grid, Snackbar, Typography } from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';
import { enviroments } from '../../enviroments/enviroments';
import { DeviceHub } from '@mui/icons-material';


const Sensores = () => {
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('');
 


  const visualizarSensores = async() =>{
    try {
      const token = localStorage.getItem('token');

      
      const buscarDevices = await axios.get(`${enviroments.api_backend}/devices`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
  
      setDevices(buscarDevices.data);
      console.log(buscarDevices.data);

      if (buscarDevices.data.length === 0) {
        // A mensagem será passada como prop para MenuLateral
        setAlerta(true);
        setTipoAlerta('info');
        setMensagemAlerta('Nenhum nó sensor cadastrado. Adicione ao menos um para enviar dados!');
        setDevices([]); // Atualiza o estado com um array vazio se não houver gateways
        return;
      }
  
      // Filtra dispositivos que têm `lastSeenAt` como null
      const dispositivosSemDados = buscarDevices.data.filter(device => device.lastSeenAt === null);
  
      if (dispositivosSemDados.length > 0) {
        // Define o número de dispositivos por linha
        const devicesPerLine = 2;
  
        // Agrupa os dispositivos em linhas
        const linhasDispositivos = [];
        for (let i = 0; i < dispositivosSemDados.length; i += devicesPerLine) {
          const linha = dispositivosSemDados.slice(i, i + devicesPerLine).map(device => device.name).join(', ');
          linhasDispositivos.push(linha);
        }
  
        // Junta as linhas com quebras de linha
        const nomesDispositivosFormatados = linhasDispositivos.join('\n');
  
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta(`Nenhum dado recebido nos seguintes nós sensores:\n${nomesDispositivosFormatados}`);
         // Adicionar um delay antes de mostrar a próxima mensagem
         await new Promise(resolve => setTimeout(resolve, 5000))
      }
  
      // Verificar quais dispositivos pararam de transmitir
      const dispositivosInativos = [];
      const now = new Date();
  
      buscarDevices.data.forEach(device => {
        if (device.lastSeenAt) {
          const lastSeenAtDate = new Date(device.lastSeenAt);
          const diffInSeconds = (now - lastSeenAtDate) / 1000;
  
          if (diffInSeconds > 450) { 
            dispositivosInativos.push(device);
          }
        }
      });
  
      if (dispositivosInativos.length > 0) {
        // Define o número de dispositivos por linha
        const devicesPerLine = 2;
  
        // Agrupa os dispositivos em linhas
        const linhasDispositivos = [];
        for (let i = 0; i < dispositivosInativos.length; i += devicesPerLine) {
          const linha = dispositivosInativos.slice(i, i + devicesPerLine).map(device => device.name).join(', ');
          linhasDispositivos.push(linha);
        }
  
        // Junta as linhas com quebras de linha
        const nomesDispositivosInativosFormatados = linhasDispositivos.join('\n');
  
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta(`Os seguintes nós sensores pararam de transmitir:\n${nomesDispositivosInativosFormatados}`);
      }
    } catch (error) {
      console.log(error)
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar devices cadastrados')
    }
  }

  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarSensores();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarSensores, 20000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);

  //useEffect(() => {
    //visualizarSensores();
  //}, []);

  const visualizarGateways = async () => {
    try {
      
      const token = localStorage.getItem('token');

      
      const buscarGateways = await axios.get(`${enviroments.api_backend}/gateways`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      setGateways(buscarGateways.data);
      console.log(buscarGateways.data);
    } catch (error) {
      console.log(error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar gateways');
    }
  }

  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarGateways();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarGateways, 20000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);
  //useEffect(() => {
    //visualizarGateways();
  //}, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => { setAlerta(false); }}
      >
        <Alert 
          variant='filled' 
          onClose={() => { setAlerta(false); }} 
          severity={tipoAlerta}
          sx={{fontFamily:'OCR A Extended', whiteSpace: 'pre-line'}}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>
      <MenuLateral titulo={'Nó Sensor'} icone={<DeviceHub />} />


      <Container sx={{ mt: 7, mb:10,  }}>
        <Grid container spacing={2} sx={{mb:10}}>
          {devices.map((device) => (
            <Grid item xs={12} sm={5} md={3} key={device.devEui}>
              <Card sx={{ height: 300,  }}>
                {/*<CardHeader
                  //title={device.name}
                  //subheader={device.devEui}
                />*/}
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left' }}>

                
                <Typography
                    sx={{
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                      alignItems: 'center',
                      fontFamily: 'OCR A Extended',
                      mb: 3,
                      mt:0,
                      fontWeight:'bold'
                      
                    }}
                  >
                    {/*<span style={{ fontWeight: 'bold' }}>Nome:</span>*/} {device.name}
                  </Typography>
                  <Typography sx={{ fontFamily: 'OCR A Extended', fontSize: 15, mb: 2 }}>
  <span style={{ fontWeight: 'bold' }}>Gateway: </span> 
  {
    // Verifica se há um gateway correspondente ao device.gatewayId
    gateways.find(gateway => gateway.id === device.gatewayId)?.name || device.gatewayName
  }
</Typography>

                <Typography sx={{  fontFamily: 'OCR A Extended', fontSize: 15, mb:2 }}>
                <span style={{ fontWeight: 'bold', fontFamily:'OCR A Extended' }}>ID:</span> {device.devAddr}
                </Typography>
                
               
                
                {/*<Typography sx={{ fontFamily: 'Roboto', fontSize: 15, mb:2 }}>
                <span style={{ fontWeight: 'bold' }}> Criação:</span>  {format(new Date(device.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>*/}

                {/*<Typography sx={{  fontFamily: 'Roboto', fontSize: 15, mb:2 }}>
                <span style={{ fontWeight: 'bold' }}> UDR: </span>  {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                </Typography>*/}

                
                <Typography 
                  sx={{  
                    fontFamily: 'OCR A Extended', 
                    fontSize: 15, 
                    mb: 0,
                    height: 160, 
                    overflow: 'auto', 
                    whiteSpace: 'pre-wrap', 
                    wordBreak: 'break-word' 
                  }}
                >                 
                  <span style={{ fontWeight: 'bold', fontSize: 15 }}>Descrição:</span> {device.description}
                </Typography>

                




                

                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Rodape />

     
    </>
  )
}

export default Sensores