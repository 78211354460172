import React, { useEffect, useState } from 'react'
import MenuLateral from '../../Components/MenuLateral' 
import Rodape from  '../../Components/Rodape'
import { Alert, Box, Button, Card,  CardContent,  Container,  Grid, Snackbar, Typography } from '@mui/material'
import axios from 'axios';
import { format } from 'date-fns';
import { enviroments } from '../../enviroments/enviroments';
import { Router } from '@mui/icons-material';

const Concentrador = () => {
  const [gateways, setGateways] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('');



  const visualizarGateways = async () => {
    try {
        const token = localStorage.getItem('token');

        const buscarGateways = await axios.get(`${enviroments.api_backend}/gateways`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

         // Ordena os gateways por nome em ordem alfabética
         const gatewaysOrdenados = buscarGateways.data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
      });

      setGateways(gatewaysOrdenados);

        //setGateways(buscarGateways.data);

        // Mensagem de erro caso não tenha nenhum gateway cadastrado
        if (buscarGateways.data.length === 0) {
            setAlerta(true);
            setTipoAlerta('info');
            setMensagemAlerta('Nenhum concentrador cadastrado. Cadastre um para receber dados!');
            setGateways([]); // Atualiza o estado com um array vazio se não houver gateways
            return;
        }

        // Mensagem de erro caso não tenha nenhum dado recebido
        if (buscarGateways.data[0].lastSeenAt === null) {
            setAlerta(true);
            setTipoAlerta('error');
            setMensagemAlerta('Nenhum dado recebido no concentrador');

            // Adicionar um delay antes de mostrar a próxima mensagem
            await new Promise(resolve => setTimeout(resolve, 3000));
        }

        // Mensagem de offline caso o gateway tenha status NEVER_SEEN ou OFFLINE
        if (buscarGateways.data[0].state === "NEVER_SEEN" || buscarGateways.data[0].state === "OFFLINE") {
            setAlerta(true);
            setTipoAlerta('warning');
            setMensagemAlerta('O concentrador está offline');
        }

        // Verificar se o gateway parou de transmitir dados
        const now = new Date();

        buscarGateways.data.forEach(gateway => {
            if (gateway.lastSeenAt) {
                const lastSeenAtDate = new Date(gateway.lastSeenAt);
                const diffInSeconds = (now - lastSeenAtDate) / 1000;

                if (diffInSeconds > 450) {
                    setAlerta(true);
                    setTipoAlerta('error');
                    setMensagemAlerta(`O concentrador ${gateway.name} parou de receber dados.`);
                }
            }
        });

        console.log(buscarGateways.data);
    } catch (error) {
        console.log(error);
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Erro ao buscar gateways');
    }
}

/*const visualizarGateways = async () => {
  try {
    const token = localStorage.getItem('token');
    
    // Verifica se o token existe antes de fazer a requisição
    if (!token) {
      throw new Error('Token não encontrado');
    }

    // Faz uma requisição para o endpoint que chama o método aceitarDados
    const buscarGateways = await axios.post(
      `${enviroments.api_backend}/gatewaysRecebidos`, // URL do seu endpoint
      [],  // Envie um array vazio se não houver dados para enviar
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    // Aqui, os dados retornados já são os dados que você quer apresentar
    const gatewaysRecebidos = buscarGateways.data; // Obtenha os dados recebidos

    // Ordena os gateways por nome em ordem alfabética
    const gatewaysOrdenados = gatewaysRecebidos.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    // Atualiza o estado com os gateways ordenados
    setGateways(gatewaysOrdenados);
    console.log(gatewaysRecebidos);
    
  } catch (error) {
    console.log(error);

    // Aqui você pode verificar se o erro é 401
    if (error.response && error.response.status === 401) {
      setMensagemAlerta('Não autorizado. Verifique o token de acesso.');
    } else {
      setMensagemAlerta('Erro ao buscar gateways');
    }

    setAlerta(true);
    setTipoAlerta('error');
  }
};*/




  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarGateways();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarGateways, 20000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);

  //useEffect(() => {
    //visualizarGateways();
  //}, []);

  //ULTIMO FUNCIONANDO 
  // Função para determinar a cor do status
  /*const getStatusColor = (state) => {
    switch (state) {
      case 'ONLINE':
        return '#00cc60'; // Verde para online
      case 'OFFLINE':
      case 'NEVER_SEEN':
        return '#d3d3d3'; // Amarelo para offline
      default:
        return '#cccccc'; // Cor padrão para outros estados
    }
  };*/

  const getStatusColor = (state, lastSeenAt) => {
    if (lastSeenAt) {
      const now = new Date();
      const lastSeenAtDate = new Date(lastSeenAt);
      const diffInSeconds = (now - lastSeenAtDate) / 1000;
  
      // Se o último envio de dados foi há mais de 120 segundos (2 minutos), retorna cinza
      if (diffInSeconds > 600) {
        return '#cccccc'; // Cinza para offline após 2 minutos, independentemente do status
      }
    }
  
    // Se o lastSeenAt está atualizado, retorna a cor baseada no estado
    switch (state) {
      case 'ONLINE':
        return '#00cc60'; // Verde para online
      case 'OFFLINE':
      case 'NEVER_SEEN':
        return '#d3d3d3'; // Cinza claro para offline/never seen
      default:
        return '#cccccc'; // Cor padrão para outros estados
    }
  };
  
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => { }}
      >
        <Alert 
          variant='filled' 
          onClose={() => { setAlerta(false) }} 
          severity={tipoAlerta}
          sx={{fontFamily:'OCR A Extended', whiteSpace: 'pre-line'}}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>
      <MenuLateral titulo={'Gateway'} icone={<Router />}/>
      
      <Container sx={{   mb:10}}>
      <Grid container spacing={2}>
        {gateways.map((gateway) => (
          <Grid item xs={12} sm={5} md={3} key={gateway.gatewayId} >
            <Card
              sx={{
                height: 300,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                backgroundColor: 'transparent',
                //borderRadius: 5,
                
                
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  textAlign: 'left',
                }}
              >
                <Typography
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    fontFamily: 'OCR A Extended',
                    mb: 3,
                    mt: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {gateway.name}
                </Typography>

                  {/* Rótulo e botão de status */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography sx={{ mr: 1, fontWeight: 'bold', fontSize: 15, fontFamily: 'OCR A Extended', }}>
                    Status:
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      //ACRESCENTEI O GATEWAY.LASTSEENAT
                      backgroundColor: getStatusColor(gateway.state, gateway.lastSeenAt),
                      borderRadius: '50%',
                      width: 20,
                      height: 20,
                      minWidth: 20,
                      p: 0,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'OCR A Extended',
                    mb: 1,
                  }}
                >
                  <span style={{ fontWeight: 'bold', fontSize: 15, mb: 2, fontFamily: 'OCR A Extended' }}>
                    ID:
                  </span>{' '}
                  {gateway.gatewayId}
                </Typography>
  
              
                {/*<Typography
                  sx={{
                    fontFamily: 'Roboto',
                    mb: 2,
                  }}
                >
                  <span style={{ fontWeight: 'bold', fontSize: 15 }}>Criação:</span>{' '}
                  {format(new Date(gateway.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                </Typography>*/}
                {/*<Typography
                  sx={{
                    fontFamily: 'Roboto',
                    mb: 2,
                  }}
                >
                  <span style={{ fontWeight: 'bold', fontSize: 15 }}>UDR:</span>
                  {gateway.lastSeenAt
                    ? format(new Date(gateway.lastSeenAt), 'dd/MM/yyyy HH:mm:ss')
                    : ''}
                </Typography>*/}
  
                <Typography
                  sx={{
                    fontFamily: 'OCR A Extended',
                    mb: 2,
                    width: '100%',
                    fontSize: 15,
                    height: 160,
                    overflow: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    
                  }}
                >
                  <span style={{ fontWeight: 'bold', marginRight: '4px', fontSize: 15, fontFamily: 'OCR A Extended' }}>
                    Descrição:
                  </span>
                  {gateway.description}
                </Typography>
  
                
                
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
      <Rodape />
      
    </>
  )
}

export default Concentrador