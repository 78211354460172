import React, { useState, useEffect } from 'react';
import { AppBar, Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, Snackbar, Alert } from '@mui/material';
import { Close, DeviceHub, Router, ScreenshotMonitor, Menu, Notifications } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MenuLateral = ({ notificacao, icone }) => {
    const [drawer, setDrawer] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('info'); 

    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (notificacao) {
            setMensagemAlerta(notificacao);
            setTipoAlerta('error'); 
            setAlerta(true);
        } else {
            setAlerta(false);
        }
    }, [notificacao]);

    const formattedDate = () => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return currentDateTime.toLocaleDateString('pt-BR', options);
    };

    const itensDoMenu = [
        { texto: 'Gateway', icone: <Router />, destino: '/concentrador' },
        { texto: 'Nó Sensor', icone: <DeviceHub />, destino: '/sensores' },
        { texto: 'Dados', icone: <ScreenshotMonitor />, destino: '/dados' },  
        { texto: 'Sair', icone: <Close />, destino: '/sair' }
    ];

    return (
        <>
        <Drawer open={drawer} onClose={() => { setDrawer(false) }}>
            <Box sx={{ width: 250, fontFamily: 'OCR A Extended' }}>
                <List sx={{ fontFamily: 'OCR A Extended' }}>
                    {itensDoMenu.map((itemDoMenu) => (
                        <ListItem key={itemDoMenu.destino}>
                            <ListItemButton 
                                onClick={() => navigate(itemDoMenu.destino)} 
                                sx={{ fontFamily: 'OCR A Extended' }}
                            >
                                <ListItemIcon>{itemDoMenu.icone}</ListItemIcon>
                                <ListItemText 
                                    primary={<span style={{ fontFamily: 'OCR A Extended' }}>{itemDoMenu.texto}</span>} 
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
        <Box sx={{ display: 'flex', mb: 7 }}>
            <CssBaseline />
            <AppBar sx={{ backgroundColor: '#033b57', position: 'relative' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>

            {/* Menu alinhado à esquerda */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ marginRight: '36px', color: "#fff" }} onClick={() => setDrawer(true)}>
            <Menu />
                </IconButton>
            </Box>

            {/* Data e Notificações alinhados à direita */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography 
                variant="body1" 
                sx={{ 
                    marginRight: '10px', 
                    color: '#fff', 
                    fontFamily: 'OCR A Extended', 
                    textAlign: 'right',
                    '@media (max-width: 600px)': {
                        whiteSpace: 'pre-line' // Quebra de linha no mobile
                    }
                }}
            >
                {formattedDate()}{"\n"}{currentDateTime.toLocaleTimeString()}
            </Typography>
            <IconButton sx={{ color: "#fff" }}>
                <Notifications />
            </IconButton>
        </Box>

        {/* Ícone absolutamente centralizado */}
        {icone && (
            <Box
                sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
            {icone}
            </Box>
        )}

    </Toolbar>
    </AppBar>

        </Box>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={alerta}
            autoHideDuration={6000}
            onClose={() => setAlerta(false)}
        >
            <Alert 
                variant='filled' 
                onClose={() => setAlerta(false)} 
                severity={tipoAlerta}
            >
                {mensagemAlerta}
            </Alert>
        </Snackbar>
    </>
    );
}

export default MenuLateral;



{/**
    import React, { useState, useEffect } from 'react';
import { AppBar, Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, Snackbar, Alert } from '@mui/material';
import { Close, DeviceHub, Router, ScreenshotMonitor, Menu, Notifications } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MenuLateral = ({ titulo, notificacao, icone }) => {
    const [drawer, setDrawer] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [alerta, setAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('info'); 

    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Atualiza a data e hora a cada segundo

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (notificacao) {
            setMensagemAlerta(notificacao);
            setTipoAlerta('error'); 
            setAlerta(true);
        } else {
            setAlerta(false);
        }
    }, [notificacao]);

    const formattedDate = () => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return currentDateTime.toLocaleDateString('pt-BR', options);
    };

    const itensDoMenu = [
        { texto: 'Gateway', icone: <Router />, destino: '/concentrador' },
        { texto: 'Nó Sensor', icone: <DeviceHub />, destino: '/sensores' },
        { texto: 'Dados', icone: <ScreenshotMonitor />, destino: '/dados' },  
        { texto: 'Sair', icone: <Close />, destino: '/sair' }
    ];

    return (
        <>
            <Drawer open={drawer} onClose={() => { setDrawer(false) }}>
                <Box sx={{ width: 250, fontFamily: 'OCR A Extended' }}>
                    <List sx={{ fontFamily: 'OCR A Extended' }}>
                        {itensDoMenu.map((itemDoMenu) => (
                            <ListItem key={itemDoMenu.destino}>
                                <ListItemButton 
                                    onClick={() => navigate(itemDoMenu.destino)} 
                                    sx={{ fontFamily: 'OCR A Extended' }}
                                >
                                    <ListItemIcon>{itemDoMenu.icone}</ListItemIcon>
                                    <ListItemText 
                                        primary={<span style={{ fontFamily: 'OCR A Extended' }}>{itemDoMenu.texto}</span>} 
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box sx={{ display: 'flex', mb: 12 }}>
                <CssBaseline />
                <AppBar sx={{ backgroundColor: '#033b57' }}>
                    <Toolbar>
                        <IconButton sx={{ marginRight: '36px', color: "#fff" }} onClick={() => setDrawer(true)}>
                            <Menu />
                        </IconButton>
                       
                        <Typography component="h1" variant="h6" sx={{ flexGrow: 1, fontFamily: 'OCR A Extended' }}>
                            {titulo}
                        </Typography>
                        <Typography variant="body1" sx={{ marginRight: '10px', color: '#fff', fontFamily: 'OCR A Extended' }}>
                            {formattedDate()} - {currentDateTime.toLocaleTimeString()}
                        </Typography>
                        <IconButton sx={{ color: "#fff" }}>
                            <Notifications />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={alerta}
                autoHideDuration={6000}
                onClose={() => setAlerta(false)}
            >
                <Alert 
                    variant='filled' 
                    onClose={() => setAlerta(false)} 
                    severity={tipoAlerta}
                >
                    {mensagemAlerta}
                </Alert>
            </Snackbar>
        </>
    );
}

export default MenuLateral;
 */}





/*





import React, { useState, useEffect } from 'react';
import { AppBar, Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { DeveloperBoard, DeviceHub,  Menu, Notifications, ScreenshotMonitor, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MenuLateral = ({ titulo, notificacao }) => {
    const [drawer, setDrawer] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Atualiza a data e hora a cada segundo

        return () => clearInterval(interval);
    }, []);

    const formattedDate = () => {
        const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };
        return currentDateTime.toLocaleDateString('pt-BR', options);
    };

    {/*const formattedDate = () => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return currentDateTime.toLocaleDateString('pt-BR', options);
    };

    const itensDoMenu = [
        //{ texto: 'Visão Geral', icone: <HomeOutlined />, destino: '/' },
        { texto: 'Concentrador', icone: <DeveloperBoard/>, destino: '/' },
        { texto: 'Nó Sensor', icone: <DeviceHub/>, destino: '/sensores' },
        { texto: 'Dados', icone: <ScreenshotMonitor/>, destino: '/dados' },  
        { texto: 'Configuração', icone: <Settings/>, destino: '/configuracoes' }
    ];

    return (
        <>
            <Drawer open={drawer}  onClose={() => { setDrawer(false) }}>
                <Box sx={{ width: 250 }}>
                    <List>
                        {itensDoMenu.map((itemDoMenu) => (
                            <ListItem key={itemDoMenu.destino}>
                                <ListItemButton onClick={() => navigate(itemDoMenu.destino)}>
                                    <ListItemIcon>{itemDoMenu.icone}</ListItemIcon>
                                    <ListItemText primary={itemDoMenu.texto} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar sx={{backgroundColor:'#13279a'}}>
                    <Toolbar>
                        <IconButton sx={{ marginRight: '36px', color: "#fff" }} onClick={() => setDrawer(true)}>
                            <Menu />
                        </IconButton>
                        <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
                            {titulo}
                        </Typography>
                        <Typography variant="body1" sx={{ marginRight: '10px', color: '#fff' }}>
                            {formattedDate()} - {currentDateTime.toLocaleTimeString()}
                        </Typography>
                        <IconButton sx={{ color: "#fff" }}>
                            <Notifications />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
        </> 
    );
}

export default MenuLateral;

*/
