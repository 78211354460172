import { createBrowserRouter } from "react-router-dom";
//import VisaoGeral from "./Pages/VisaoGeral/VisaoGeral";
import Sensores from "./Pages/Sensores/Sensores";
//import Sobre from "./Pages/Sobre/Sobre";
import Login from "./Pages/Login/Login";
import RotaPrivada from "./Pages/RotaPrivada/RotaPrivada";
import Sair from "./Pages/Sair/Sair"


import Concentrador from "./Pages/Concentrador/Concentrador";

import Dados from "./Pages/Dados/Dados";


const routers = createBrowserRouter([
   
    //{
        //path: '/sobre',
        //element: <Sobre/>
    //},
    {
        path: '/sensores',
        element: <RotaPrivada element={<Sensores/>}/>
    },
    {
        path: '/concentrador',
        element: <RotaPrivada element={<Concentrador/>}/>
    },
    //{
        //path: '/visao-geral',
        //element: <RotaPrivada element={<VisaoGeral/>}/>
    //},

    {
        path: '/dados',
        element: <RotaPrivada element={<Dados/>}/>
    },
    {
        path: '/',
        element: <Login/>
    },
    {
        path: '/sair',
        element: <Sair/>
    }

])

export default routers;