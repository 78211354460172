import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';
import { Download, FilterAlt, ScreenshotMonitor } from '@mui/icons-material';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  

  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
 

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

 
 //FUNÇÃO PARA VISUALIZAR GATEWAYS
  const visualizarGateways = async () => {
    try {
      
      const token = localStorage.getItem('token');

      
      const buscarGateways = await axios.get(`${enviroments.api_backend}/gateways`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      setGateways(buscarGateways.data);
      console.log(buscarGateways.data);
    } catch (error) {
      console.log(error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar gateways');
    }
  }

  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarGateways();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarGateways, 30000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);
  //useEffect(() => {
    //visualizarGateways();
  //}, []);
 
  //FUNÇÃO PARA VISUALIZAR NÓS SENSORES
  const visualizarSensores = async() =>{
    try {
      const token = localStorage.getItem('token');

      
      const buscarDevices = await axios.get(`${enviroments.api_backend}/devices`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
  
      setDevices(buscarDevices.data);
      console.log(buscarDevices.data);
    } catch (error) {
      console.log(error)
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar devices cadastrados')
    }
  }

  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarSensores();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarSensores, 30000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);
  //useEffect(() => {
    //visualizarSensores();
  //}, []);


// FUNÇÃO PARA VISUALIZAR MÉTRICAS

const visualizarMetricas = async () => {
  try {
    const token = localStorage.getItem('token');
    
    const response = await axios.get(`${enviroments.api_backend}/metrics`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('Response:', response.data);

    // Verificar se a resposta é um array
    if (Array.isArray(response.data)) {
      const novosDados = response.data;
      
      // Ordenar os dados pelo lastSeenAt em ordem decrescente (mais recente primeiro)
      const dadosOrdenados = novosDados.sort((a, b) => new Date(b.lastSeenAt) - new Date(a.lastSeenAt));
      
      // Remover registros duplicados com base em devAddr e lastSeenAt
      const dadosUnicos = [];
      const seen = new Set();

      dadosOrdenados.forEach((dado) => {
        const key = `${dado.devAddr}_${dado.lastSeenAt}`;
        if (!seen.has(key)) {
          seen.add(key);
          dadosUnicos.push(dado);
        }
      });

      // Selecionar os últimos 70 registros
      const ultimosRegistros = dadosUnicos.slice(0, 70);
      
      setDevicesLocais(ultimosRegistros);
    } else {
      throw new Error('A resposta não é um array válido.');
    }
  } catch (error) {
    console.error('Erro ao atualizar métricas:', error);
    setAlerta(true);
    setTipoAlerta('error');
    setMensagemAlerta('Erro ao atualizar métricas');
  }
};



useEffect(() => {
  // Chama a função imediatamente ao carregar o componente
  visualizarMetricas();

  // Define o intervalo para chamar a função a cada 30 segundos
  const intervalId = setInterval(visualizarMetricas, 30000);

  // Limpa o intervalo quando o componente for desmontado
  return () => clearInterval(intervalId);
}, []);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
     //ALTERAR PARA ARQUIVO DE DOWNLOAD
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Gateway', 'Nó Sensor', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Gateway', 'Nó Sensor', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Gateway', 'Nó Sensor', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};





const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};

const handleResetFiltro = () => {
  setFiltroColuna('');  // Reseta a coluna de filtro
  setFiltroNome('');    // Reseta o nome do filtro
};


  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert 
          variant='filled' 
          onClose={() => setAlerta(false)} 
          severity={tipoAlerta}
          sx={{fontFamily:'OCR A Extended', whiteSpace: 'pre-line'}}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} icone={<ScreenshotMonitor/>}/>

      <Container sx={{ mt: 7, mb:10, }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
  <FormControl sx={{ minWidth: 80 }}>
    <Select
      value={filtroColuna}
      onChange={handleFiltroColunaChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
      sx={{ height: 40, fontFamily:'OCR A Extended' }}
    >
      {/* Habilitar o clique no ícone e chamando a função de reset */}
      <MenuItem
        value="" 
        onClick={handleResetFiltro} // Chama a função de reset
      >
        <FilterAlt style={{ marginRight: 8, cursor: 'pointer' }} />
        
      </MenuItem>
      <MenuItem value="gatewayName" sx={{ fontFamily: 'OCR A Extended' }}>Gateway</MenuItem>
      <MenuItem value="name" sx={{ fontFamily: 'OCR A Extended' }}>Nó Sensor</MenuItem>
      <MenuItem value="devAddr" sx={{ fontFamily: 'OCR A Extended' }}>ID</MenuItem>
    </Select>
  </FormControl>

  {filtroColuna && (
    <TextField
    id="filtro-nome"
    label="Filtrar"
    variant="outlined"
    value={filtroNome}
    InputLabelProps={{
      sx: {
        fontFamily: 'OCR A Extended',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        transform: 'translateY(-50%)',  // Centraliza o label quando o campo não está focado
        transition: 'all 0.2s ease-out',  // Suaviza a transição
        '&.MuiInputLabel-shrink': {
          top: '-6px',  // Ajusta a posição quando o label vai para o topo
          transform: 'translateY(0)',  // Remove a translação ao focar
          fontSize: '9.8px',  // Diminui o tamanho do texto quando está no topo
          left:'10px'
          
        }
      }
    }}
    onChange={handleFiltroNomeChange}
    sx={{
      marginLeft: 2,
      width: 170,
      fontFamily: 'OCR A Extended',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    InputProps={{
      sx: {
        height: 40,  // Ajusta a altura do campo
        padding: 0,
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiInputBase-input': {
          height: 40,
          padding: '0 14px',
          fontFamily: 'OCR A Extended',
          alignItems: 'center',
        },
      },
    }}
  />
  )}
</Box>


        <TableContainer component={Paper} xs={12} md={6}  >
          <Table>
            <TableHead>
              <TableRow>
                {/*<TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Concentrador</TableCell>*/}
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Nó sensor</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >ID</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Dendrômetro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >FS1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >FS2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Data/Hora</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {(
    rowsPerPage > 0
    ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : devicesLocais
  )
  .filter(device => 
    !filtroColuna || 
    (device[filtroColuna] && device[filtroColuna].toString().toLowerCase().includes(filtroNome.toLowerCase()))
  )
  .map((device) => (
    <TableRow key={device.id}>
      {/*<TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>*/}
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.gatewayName}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.name}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.devAddr}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.bateria}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.dendrometro}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
      <TableCell sx={{ fontFamily: 'OCR A Extended', textAlign: 'center' }}>
        {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
      </TableCell>
    </TableRow>
  ))}
  {emptyRows > 0 && (
    <TableRow style={{ height: 53 * emptyRows }}>
      <TableCell colSpan={7} />
    </TableRow>
  )}
</TableBody>

          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#033b57', height:55, fontFamily:'OCR A Extended', width: 350,}} startIcon={<Download/>}>
            Download
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom sx={{fontFamily:'OCR A Extended'}}>
              Selecione os nós sensores e os parâmetros desejados:
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom sx={{fontFamily:'OCR A Extended'}}>
    Nós sensores
  </Typography>
  <div style={{ maxHeight: '300px', overflowY: 'auto', fontFamily: 'OCR A Extended' }}>
  <FormGroup sx={{ fontFamily: 'OCR A Extended' }}>
    {[
      { devEui: 'todos', name: 'Todos os Nós sensores' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
            sx={{ '&.Mui-checked': { color: '#033b57' } }} // Opcional: altera a cor do checkbox selecionado
          />
        }
        label={<span style={{ fontFamily: 'OCR A Extended' }}>{device.name}</span>} // Aplica a fonte ao label
        sx={{ fontFamily: 'OCR A Extended' }} // Aplica a fonte ao FormControlLabel
      />
    ))}
  </FormGroup>
</div>

</Grid>


<Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom sx={{ fontFamily: 'OCR A Extended' }}>
    Parâmetros
  </Typography>

  <FormGroup sx={{ fontFamily: 'OCR A Extended' }}>
    {[
      'todas as métricas',
      'bateria',
      'dendrometro',
      'fluxo_seiva_1',
      'fluxo_seiva_2',
      'sensor_reserva',
      
    ].map(property => (
      <FormControlLabel
        key={property}
        control={
          <Checkbox
            checked={selectedProperties[property]}
            onChange={() => handleToggleProperty(property)}
            sx={{ '&.Mui-checked': { color: '#033b57' } }} // Opcional: altera a cor do checkbox selecionado
          />
        }
        label={
          <span style={{ fontFamily: 'OCR A Extended' }}>
            {property === 'fluxo_seiva_1'
              ? 'Fluxo de Seiva 1'
              : property === 'fluxo_seiva_2'
              ? 'Fluxo de Seiva 2'
              : property === 'dendrometro'
              ? 'Dendrômetro'
              : property === 'bateria'
              ? 'Bateria'
              : property === 'sensor_reserva'
              ? 'Sensor Reserva'
              : property === 'todas as métricas'
              ? 'Todos os parâmetros'
              : property}
          </span>
        }
        sx={{ fontFamily: 'OCR A Extended' }} // Aplica a fonte ao FormControlLabel
      />
    ))}
  </FormGroup>
</Grid>


            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontFamily: 'OCR A Extended' }}>
  Formato
</Typography>
<RadioGroup
  value={fileType}
  onChange={(e) => setFileType(e.target.value)}
  row
  sx={{ fontFamily: 'OCR A Extended' }}
>
  <FormControlLabel 
    value="csv" 
    control={<Radio />} 
    label={<span style={{ fontFamily: 'OCR A Extended' }}>CSV</span>} 
    sx={{ fontFamily: 'OCR A Extended' }} 
  />
  <FormControlLabel 
    value="txt" 
    control={<Radio />} 
    label={<span style={{ fontFamily: 'OCR A Extended' }}>TXT</span>} 
    sx={{ fontFamily: 'OCR A Extended' }} 
  />
  <FormControlLabel 
    value="pdf" 
    control={<Radio />} 
    label={<span style={{ fontFamily: 'OCR A Extended' }}>PDF</span>} 
    sx={{ fontFamily: 'OCR A Extended' }} 
  />
</RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2,  }}>
              <Grid item xs={6}>
                <Button 
                  variant='contained' 
                  fullWidth onClick={handleDownload} 
                  startIcon={<Download/>} 
                  sx={{
                    backgroundColor:'#033b57', 
                    color:'#fff', 
                    height:55,
                    fontFamily: 'OCR A Extended',
                    width: 350,
                    }} 
                  >
                  Download 
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;

/*
           <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Dispositivos
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>
*/

/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
    visualizarGateways();
    visualizarSensores();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const acionarEnvioDeDados = async () => {
    const url = `${enviroments.api_backend}/enviarMetrics`; // Ajuste a URL conforme sua configuração
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });
  
      console.log(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    acionarEnvioDeDados();
  }, []);

  //Método para visualizar os gateways vinculados aos devices
  const visualizarGateways = async () => {
    
    const url = `${enviroments.api_backend}/api/gateways`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      console.log(response);
      if (response.data.length === 0) {
        console.log('Nenhum gateway cadastrado');
        return;
      }

      setGateways(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  //Função para visualizar os devices do sistema
  const visualizarSensores = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/view/devices`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      if (response.data.length === 0) {
        console.log('Nenhum dispositivo cadastrado');
        return;
      }

      setDevices(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const visualizarMetricas = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54'
        }
      });
      console.log(response.data);
      if (response.data.devicesLocais) {
        const novosDados = response.data.devicesLocais;
        const ultimosRegistros = novosDados.slice(-70).reverse();

        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('Não foram encontrados devicesLocais na resposta.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,
    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    const csvContent = [
      ['Name', 'DevEui', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
      ...data.map(device => [
        device.name,
        device.devEui,
        ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
      ])
    ].map(e => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadTXT = (data, filename) => {
    const txtContent = data.map(device => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      return `Name: ${device.name}, DevEui: ${device.devEui}, ${properties}`;
    }).join('\n');

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF();
    doc.text('Device Metrics', 10, 10);
    data.forEach((device, index) => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      doc.text(`Name: ${device.name}, DevEui: ${device.devEui}, ${properties}`, 10, 20 + (index * 10));
    });
    doc.save(`${filename}.pdf`);
  };

  const handleDownload = () => {
    let dataToDownload = devicesLocais;
  
    // Aplicar filtro de dispositivos selecionados
    if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
      dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
    }
  
    // Aplicar filtro de propriedades selecionadas
    dataToDownload = dataToDownload.map(device => {
      const filteredDevice = { name: device.name, devEui: device.devEui };
  
      Object.entries(selectedProperties).forEach(([key, value]) => {
        if (value) filteredDevice[key] = device[key];
      });
  
      return filteredDevice;
    });
  
    console.log('Dados para download:', dataToDownload);
  
    if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'metrics');
    } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'metrics');
    } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'metrics');
    }
  
    handleCloseModal();
  };
  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome</MenuItem>
              <MenuItem value="devEui" sx={{ fontFamily:'Roboto'}} >DevEui</MenuItem>
              
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Endereço do Dispositivo</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Último dado Recebido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  
                  {devices.map((device) => (
                  <TableCell key={device.devEui} sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>{device.gatewayName}</TableCell>
                  ))}

                  
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Selecionar Métricas para Download
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download Selecionado
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;

*/

/**
 *  <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  
                  {devices.map((device) => (
                  <TableCell key={device.devEui} sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>{device.gatewayName}</TableCell>
                  ))}

                  
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
 */

            /**
             *   const downloadTXT = (data, filename) => {
    const txtContent = data.map(device => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      return `Concentrador: ${device.gatewayName}, Name: ${device.name},  Endereço do Dispositivo: ${device.devAddr}, Último dado Recebido: ${device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}, ${properties}`;
    }).join('\n');

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
             */


  /**
   * const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    if (data.length === 0) {
        doc.text('', 10, y);
    } else {
        data.forEach((device, index) => {
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
            let text = `Concentrador: ${device.gatewayName}, Name: ${device.name}, Endereço do Dispositivo: ${device.devAddr}, Último dado Recebido: ${device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}, ${properties}`;

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(text, 270); // Ajuste a largura conforme necessário
            
            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};
   */


///ULTIMO QUE TAVA FUNCIONANDO OK:
/**
 * import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
    visualizarGateways();
    visualizarSensores();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const acionarEnvioDeDados = async () => {
    const url = `${enviroments.api_backend}/enviarMetrics`; // Ajuste a URL conforme sua configuração
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });
  
      console.log(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    acionarEnvioDeDados();
  }, []);

  //Método para visualizar os gateways vinculados aos devices
  const visualizarGateways = async () => {
    
    const url = `${enviroments.api_backend}/api/gateways`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      console.log(response);
      if (response.data.length === 0) {
        console.log('Nenhum gateway cadastrado');
        return;
      }

      setGateways(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  //Função para visualizar os devices do sistema
  const visualizarSensores = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/view/devices`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      if (response.data.length === 0) {
        console.log('Nenhum dispositivo cadastrado');
        return;
      }

      setDevices(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const visualizarMetricas = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54'
        }
      });
      console.log(response.data);
      if (response.data.devicesLocais) {
        const novosDados = response.data.devicesLocais;
        const ultimosRegistros = novosDados.slice(-70).reverse();

        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('Não foram encontrados devicesLocais na resposta.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};





const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};

  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome</MenuItem>
              <MenuItem value="devAddr" sx={{ fontFamily:'Roboto'}} >Endereço do Device</MenuItem>
              
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Endereço do Dispositivo</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Último dado Recebido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  
                  

                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download dos Dados
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Selecionar Métricas para Download
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download Selecionado
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;
 */

//COM FILTRO FUNCIONANDO DIRETO NA TABELA:

/**
 * import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
    visualizarGateways();
    visualizarSensores();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const acionarEnvioDeDados = async () => {
    const url = `${enviroments.api_backend}/enviarMetrics`; // Ajuste a URL conforme sua configuração
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });
  
      console.log(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    acionarEnvioDeDados();
  }, []);

  //Método para visualizar os gateways vinculados aos devices
  const visualizarGateways = async () => {
    
    const url = `${enviroments.api_backend}/api/gateways`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      console.log(response);
      if (response.data.length === 0) {
        console.log('Nenhum gateway cadastrado');
        return;
      }

      setGateways(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  //Função para visualizar os devices do sistema
  const visualizarSensores = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/view/devices`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      if (response.data.length === 0) {
        console.log('Nenhum dispositivo cadastrado');
        return;
      }

      setDevices(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const visualizarMetricas = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54'
        }
      });
      console.log(response.data);
      if (response.data.devicesLocais) {
        const novosDados = response.data.devicesLocais;
        const ultimosRegistros = novosDados.slice(-70).reverse();

        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('Não foram encontrados devicesLocais na resposta.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};





const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};

  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome</MenuItem>
              <MenuItem value="devAddr" sx={{ fontFamily:'Roboto'}} >Endereço do Device</MenuItem>
              
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Endereço do Dispositivo</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Último dado Recebido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {(
    rowsPerPage > 0
    ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : devicesLocais
  )
  .filter(device => 
    !filtroColuna || 
    (device[filtroColuna] && device[filtroColuna].toString().toLowerCase().includes(filtroNome.toLowerCase()))
  )
  .map((device) => (
    <TableRow key={device.id}>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
      <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
        {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
      </TableCell>
    </TableRow>
  ))}
  {emptyRows > 0 && (
    <TableRow style={{ height: 53 * emptyRows }}>
      <TableCell colSpan={7} />
    </TableRow>
  )}
</TableBody>

          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download dos Dados
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Selecionar Métricas para Download
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download Selecionado
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;
 */
/*
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
    visualizarGateways();
    visualizarSensores();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const acionarEnvioDeDados = async () => {
    const url = `${enviroments.api_backend}/enviarMetrics`; // Ajuste a URL conforme sua configuração
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });
  
      console.log(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    acionarEnvioDeDados();
  }, []);

  //Método para visualizar os gateways vinculados aos devices
  const visualizarGateways = async () => {
    
    const url = `${enviroments.api_backend}/api/gateways`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      console.log(response);
      if (response.data.length === 0) {
        console.log('Nenhum gateway cadastrado');
        return;
      }

      setGateways(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  //Função para visualizar os devices do sistema
  const visualizarSensores = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/view/devices`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      if (response.data.length === 0) {
        console.log('Nenhum dispositivo cadastrado');
        return;
      }

      setDevices(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const visualizarMetricas = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54'
        }
      });
      console.log(response.data);
      if (response.data.devicesLocais) {
        const novosDados = response.data.devicesLocais;
        const ultimosRegistros = novosDados.slice(-70).reverse();

        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('Não foram encontrados devicesLocais na resposta.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,
    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    const csvContent = [
      ['Name', 'DevEui', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
      ...data.map(device => [
        device.name,
        device.devEui,
        ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
      ])
    ].map(e => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadTXT = (data, filename) => {
    const txtContent = data.map(device => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      return `Name: ${device.name}, DevEui: ${device.devEui}, ${properties}`;
    }).join('\n');

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF();
    doc.text('Device Metrics', 10, 10);
    data.forEach((device, index) => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      doc.text(`Name: ${device.name}, DevEui: ${device.devEui}, ${properties}`, 10, 20 + (index * 10));
    });
    doc.save(`${filename}.pdf`);
  };

  const handleDownload = () => {
    let dataToDownload = devicesLocais;
  
    // Aplicar filtro de dispositivos selecionados
    if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
      dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
    }
  
    // Aplicar filtro de propriedades selecionadas
    dataToDownload = dataToDownload.map(device => {
      const filteredDevice = { name: device.name, devEui: device.devEui };
  
      Object.entries(selectedProperties).forEach(([key, value]) => {
        if (value) filteredDevice[key] = device[key];
      });
  
      return filteredDevice;
    });
  
    console.log('Dados para download:', dataToDownload);
  
    if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'metrics');
    } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'metrics');
    } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'metrics');
    }
  
    handleCloseModal();
  };
  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome</MenuItem>
              <MenuItem value="devEui" sx={{ fontFamily:'Roboto'}} >DevEui</MenuItem>
              
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Endereço do Dispositivo</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Último dado Recebido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  
                  {devices.map((device) => (
                  <TableCell key={device.devEui} sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>{device.gatewayName}</TableCell>
                  ))}

                  
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Selecionar Métricas para Download
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download Selecionado
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;

*/

/**
 *  <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  
                  {devices.map((device) => (
                  <TableCell key={device.devEui} sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>{device.gatewayName}</TableCell>
                  ))}

                  
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
 */

            /**
             *   const downloadTXT = (data, filename) => {
    const txtContent = data.map(device => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      return `Concentrador: ${device.gatewayName}, Name: ${device.name},  Endereço do Dispositivo: ${device.devAddr}, Último dado Recebido: ${device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}, ${properties}`;
    }).join('\n');

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
             */


  /**
   * const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    if (data.length === 0) {
        doc.text('', 10, y);
    } else {
        data.forEach((device, index) => {
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
            let text = `Concentrador: ${device.gatewayName}, Name: ${device.name}, Endereço do Dispositivo: ${device.devAddr}, Último dado Recebido: ${device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}, ${properties}`;

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(text, 270); // Ajuste a largura conforme necessário
            
            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};
   */


///ULTIMO QUE TAVA FUNCIONANDO OK:
/**
 * import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
    visualizarGateways();
    visualizarSensores();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const acionarEnvioDeDados = async () => {
    const url = `${enviroments.api_backend}/enviarMetrics`; // Ajuste a URL conforme sua configuração
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });
  
      console.log(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    acionarEnvioDeDados();
  }, []);

  //Método para visualizar os gateways vinculados aos devices
  const visualizarGateways = async () => {
    
    const url = `${enviroments.api_backend}/api/gateways`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      console.log(response);
      if (response.data.length === 0) {
        console.log('Nenhum gateway cadastrado');
        return;
      }

      setGateways(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  //Função para visualizar os devices do sistema
  const visualizarSensores = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/view/devices`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      if (response.data.length === 0) {
        console.log('Nenhum dispositivo cadastrado');
        return;
      }

      setDevices(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const visualizarMetricas = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54'
        }
      });
      console.log(response.data);
      if (response.data.devicesLocais) {
        const novosDados = response.data.devicesLocais;
        const ultimosRegistros = novosDados.slice(-70).reverse();

        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('Não foram encontrados devicesLocais na resposta.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};





const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};

  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome</MenuItem>
              <MenuItem value="devAddr" sx={{ fontFamily:'Roboto'}} >Endereço do Device</MenuItem>
              
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Endereço do Dispositivo</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Último dado Recebido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  
                  

                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download dos Dados
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Selecionar Métricas para Download
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download Selecionado
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;
 */

//COM FILTRO FUNCIONANDO DIRETO NA TABELA:

/**
 * import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
    visualizarGateways();
    visualizarSensores();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const acionarEnvioDeDados = async () => {
    const url = `${enviroments.api_backend}/enviarMetrics`; // Ajuste a URL conforme sua configuração
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });
  
      console.log(response.data);
  
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    acionarEnvioDeDados();
  }, []);

  //Método para visualizar os gateways vinculados aos devices
  const visualizarGateways = async () => {
    
    const url = `${enviroments.api_backend}/api/gateways`;

    try {
      const response = await axios.get(url, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      console.log(response);
      if (response.data.length === 0) {
        console.log('Nenhum gateway cadastrado');
        return;
      }

      setGateways(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  //Função para visualizar os devices do sistema
  const visualizarSensores = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/view/devices`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
        },
      });

      if (response.data.length === 0) {
        console.log('Nenhum dispositivo cadastrado');
        return;
      }

      setDevices(response.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  const visualizarMetricas = async () => {
    try {
      
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54',
          'Grpc-Metadata-Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJjaGlycHN0YWNrIiwiaXNzIjoiY2hpcnBzdGFjayIsInN1YiI6IjJlNjQwMDgzLTVlZjQtNGFlYy05ZTEwLTRjMjQ0M2EzOGY1MSIsInR5cCI6ImtleSJ9.qA8Im0WXbF8fDGdG12EGgAyusPIeS3Gkz4XoFSZXz54'
        }
      });
      console.log(response.data);
      if (response.data.devicesLocais) {
        const novosDados = response.data.devicesLocais;
        const ultimosRegistros = novosDados.slice(-70).reverse();

        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('Não foram encontrados devicesLocais na resposta.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};





const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};

  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome</MenuItem>
              <MenuItem value="devAddr" sx={{ fontFamily:'Roboto'}} >Endereço do Device</MenuItem>
              
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Endereço do Dispositivo</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Último dado Recebido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {(
    rowsPerPage > 0
    ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : devicesLocais
  )
  .filter(device => 
    !filtroColuna || 
    (device[filtroColuna] && device[filtroColuna].toString().toLowerCase().includes(filtroNome.toLowerCase()))
  )
  .map((device) => (
    <TableRow key={device.id}>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devAddr}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
      <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
      <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
        {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
      </TableCell>
    </TableRow>
  ))}
  {emptyRows > 0 && (
    <TableRow style={{ height: 53 * emptyRows }}>
      <TableCell colSpan={7} />
    </TableRow>
  )}
</TableBody>

          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download dos Dados
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Selecionar Métricas para Download
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download Selecionado
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;
 */

{/**
  import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [devices, setDevices] = useState([]);

  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const visualizarMetricas = async () => {
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${enviroments.api_backend}/buscarMetrics`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('Response:', response.data);
      
      // Verificar se a resposta é um array
      if (Array.isArray(response.data)) {
        const novosDados = response.data;
        const ultimosRegistros = novosDados.slice(-70).reverse();
        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('A resposta não é um array válido.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };
  
  
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,
    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    const csvContent = [
      ['Name', 'DevEui', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
      ...data.map(device => [
        device.name,
        device.devEui,
        ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
      ])
    ].map(e => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadTXT = (data, filename) => {
    const txtContent = data.map(device => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      return `Name: ${device.name}, DevEui: ${device.devEui}, ${properties}`;
    }).join('\n');

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF();
    doc.text('Device Metrics', 10, 10);
    data.forEach((device, index) => {
      const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
        .map(prop => `${prop}: ${device[prop] || ''}`).join(', ');
      doc.text(`Name: ${device.name}, DevEui: ${device.devEui}, ${properties}`, 10, 20 + (index * 10));
    });
    doc.save(`${filename}.pdf`);
  };

  const handleDownload = () => {
    let dataToDownload = devicesLocais;
  
    // Aplicar filtro de dispositivos selecionados
    if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
      dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
    }
  
    // Aplicar filtro de propriedades selecionadas
    dataToDownload = dataToDownload.map(device => {
      const filteredDevice = { name: device.name, devEui: device.devEui };
  
      Object.entries(selectedProperties).forEach(([key, value]) => {
        if (value) filteredDevice[key] = device[key];
      });
  
      return filteredDevice;
    });
  
    console.log('Dados para download:', dataToDownload);
  
    if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'metrics');
    } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'metrics');
    } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'metrics');
    }
  
    handleCloseModal();
  };
  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert variant='filled' onClose={() => setAlerta(false)} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10 }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
            >
              <MenuItem value="" disabled>
                Escolher coluna de filtro
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Gateway</MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'Roboto'}} >Nome / Device</MenuItem>
              <MenuItem value="devEui" sx={{ fontFamily:'Roboto'}} >DevEui</MenuItem>
              <MenuItem value="bateria" sx={{ fontFamily:'Roboto'}} >Bateria</MenuItem>
              <MenuItem value="dendrometro" sx={{ fontFamily:'Roboto' }} >Dendrometro</MenuItem>
              <MenuItem value="fluxo_seiva_1" sx={{ fontFamily:'Roboto' }} >Fluxo Seiva 1</MenuItem>
              <MenuItem value="fluxo_seiva_2" sx={{ fontFamily:'Roboto'}} >Fluxo Seiva 2</MenuItem>
              <MenuItem value="sensor_reserva" sx={{ fontFamily:'Roboto'}} >Sensor Reserva</MenuItem>
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2 }}
            />
          )}
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Nome / Device</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >DevEui</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Dendrometro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Fluxo Seiva 2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Sensor Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : devicesLocais
              ).map((device) => (
                <TableRow key={device.id}>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.name}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.devEui}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.bateria}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                  <TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                  
                  <TableCell sx={{ fontFamily: 'Roboto', textAlign: 'center' }}>
                                {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                  </TableCell>
                  
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5,9]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#00cc60'}}>
            Download
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom>
              Filtros de Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom>
    Selecionar Dispositivos para Download
  </Typography>
  <FormGroup>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
          />
        }
        label={device.name}
      />
    ))}
  </FormGroup>
</Grid>



              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Métricas
                </Typography>
                <FormGroup>
                  {[
                    'bateria',
                    'dendrometro',
                    'fluxo_seiva_1',
                    'fluxo_seiva_2',
                    'sensor_reserva',
                    'todas as métricas'
                  ].map(property => (
                    <FormControlLabel
                      key={property}
                      control={
                        <Checkbox
                          checked={selectedProperties[property]}
                          onChange={() => handleToggleProperty(property)}
                        />
                      }
                      label={property === 'todos' ? 'Todos' : property}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Selecionar Tipo de Arquivo
            </Typography>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              row
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="txt" control={<Radio />} label="TXT" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
            </RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth onClick={handleDownload}>
                  Download 
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;
  */}




//////////////////////////////////////////////////////////////////////20/09///////////////////////////////////////////


//////////ATUAL 20/09

  {/**
    import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';
import { Download, FilterAlt } from '@mui/icons-material';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  
  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    visualizarMetricas();
 

    const interval = setInterval(() => {
      visualizarMetricas();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

 
 //FUNÇÃO PARA VISUALIZAR GATEWAYS
  const visualizarGateways = async () => {
    try {
      
      const token = localStorage.getItem('token');

      
      const buscarGateways = await axios.get(`${enviroments.api_backend}/gateways`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      setGateways(buscarGateways.data);
      console.log(buscarGateways.data);
    } catch (error) {
      console.log(error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar gateways');
    }
  }

  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarGateways();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarGateways, 30000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);
  //useEffect(() => {
    //visualizarGateways();
  //}, []);
 
  //FUNÇÃO PARA VISUALIZAR NÓS SENSORES
  const visualizarSensores = async() =>{
    try {
      const token = localStorage.getItem('token');

      
      const buscarDevices = await axios.get(`${enviroments.api_backend}/devices`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
  
      setDevices(buscarDevices.data);
      console.log(buscarDevices.data);
    } catch (error) {
      console.log(error)
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar devices cadastrados')
    }
  }

  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarSensores();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarSensores, 30000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
}, []);
  //useEffect(() => {
    //visualizarSensores();
  //}, []);


// FUNÇÃO PARA VISUALIZAR MÉTRICAS
// FUNÇÃO PARA VISUALIZAR MÉTRICAS
const visualizarMetricas = async () => {
  try {
    const token = localStorage.getItem('token');
    
    const response = await axios.get(`${enviroments.api_backend}/metrics`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('Response:', response.data);

    // Verificar se a resposta é um array
    if (Array.isArray(response.data)) {
      const novosDados = response.data;
      
      // Ordenar os dados pelo lastSeenAt em ordem decrescente (mais recente primeiro)
      const dadosOrdenados = novosDados.sort((a, b) => new Date(b.lastSeenAt) - new Date(a.lastSeenAt));
      
      // Remover registros duplicados com base em devAddr e lastSeenAt
      const dadosUnicos = [];
      const seen = new Set();

      dadosOrdenados.forEach((dado) => {
        const key = `${dado.devAddr}_${dado.lastSeenAt}`;
        if (!seen.has(key)) {
          seen.add(key);
          dadosUnicos.push(dado);
        }
      });

      // Selecionar os últimos 70 registros
      const ultimosRegistros = dadosUnicos.slice(0, 70);
      
      setDevicesLocais(ultimosRegistros);
    } else {
      throw new Error('A resposta não é um array válido.');
    }
  } catch (error) {
    console.error('Erro ao atualizar métricas:', error);
    setAlerta(true);
    setTipoAlerta('error');
    setMensagemAlerta('Erro ao atualizar métricas');
  }
};



useEffect(() => {
  // Chama a função imediatamente ao carregar o componente
  visualizarMetricas();

  // Define o intervalo para chamar a função a cada 30 segundos
  const intervalId = setInterval(visualizarMetricas, 30000);

  // Limpa o intervalo quando o componente for desmontado
  return () => clearInterval(intervalId);
}, []);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (deviceId) => {
    const currentIndex = selectedDevices.indexOf(deviceId);
    const newSelectedDevices = [...selectedDevices];
  
    if (deviceId === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(deviceId);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Concentrador', 'Name', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
};





const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devEui));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};

  

  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert 
          variant='filled' 
          onClose={() => setAlerta(false)} 
          severity={tipoAlerta}
          sx={{fontFamily:'OCR A Extended'}}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      <MenuLateral titulo={'Dados'} />

      <Container sx={{ mt: 10, mb:10, }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', }}>
          <FormControl sx={{ minWidth: 80, minHeight:20 }}>
            <Select
              value={filtroColuna}
              onChange={handleFiltroColunaChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Escolher coluna de filtro' }}
              sx={{height:40}}
            >
              <MenuItem value="" disabled>
              <FilterAlt style={{ marginRight: 8 }} />
              </MenuItem>
              <MenuItem value="name" sx={{ fontFamily:'OCR A Extended', }}  >Nome</MenuItem>
              <MenuItem value="devAddr" sx={{ fontFamily:'OCR A Extended'}} >ID</MenuItem>
              <MenuItem value="devAddr" sx={{ fontFamily:'OCR A Extended'}} >ID</MenuItem>
            </Select>
          </FormControl>
          {filtroColuna && (
            <TextField
              id="filtro-nome"
              label="Filtrar por nome"
              variant="outlined"
              value={filtroNome}
              onChange={handleFiltroNomeChange}
              sx={{ marginLeft: 2, height:40 }}
            />
          )}
        </Box>
        <TableContainer component={Paper} xs={12} md={6}  >
          <Table>
            <TableHead>
              <TableRow>
                
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Gateway</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Nó sensor</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >ID</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Bateria</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Dendrômetro</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >FS1</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >FS2</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Reserva</TableCell>
                <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Data/Hora</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
  {(
    rowsPerPage > 0
    ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : devicesLocais
  )
  .filter(device => 
    !filtroColuna || 
    (device[filtroColuna] && device[filtroColuna].toString().toLowerCase().includes(filtroNome.toLowerCase()))
  )
  .map((device) => (
    <TableRow key={device.id}>
      
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.gatewayName}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.name}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.devAddr}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.bateria}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.dendrometro}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
      <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
      <TableCell sx={{ fontFamily: 'OCR A Extended', textAlign: 'center' }}>
        {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
      </TableCell>
    </TableRow>
  ))}
  {emptyRows > 0 && (
    <TableRow style={{ height: 53 * emptyRows }}>
      <TableCell colSpan={7} />
    </TableRow>
  )}
</TableBody>

          </Table>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={devicesLocais.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleOpenModal} sx={{backgroundColor: '#033b57', height:55, fontFamily:'OCR A Extended'}} startIcon={<Download/>}>
            Download
          </Button>
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" id="modal-title" gutterBottom sx={{fontFamily:'OCR A Extended'}}>
              Download
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom sx={{fontFamily:'OCR A Extended'}}>
    Dispositivos
  </Typography>
  <div style={{ maxHeight: '300px', overflowY: 'auto', fontFamily: 'OCR A Extended' }}>
  <FormGroup sx={{ fontFamily: 'OCR A Extended' }}>
    {[
      { devEui: 'todos', name: 'Todos os Devices' },
      ...filteredDevices
    ].map(device => (
      <FormControlLabel
        key={device.devEui} // Usamos devEui como chave única
        control={
          <Checkbox
            checked={selectedDevices.includes(device.devEui)}
            onChange={() => handleToggleDevice(device.devEui)}
            sx={{ '&.Mui-checked': { color: '#033b57' } }} // Opcional: altera a cor do checkbox selecionado
          />
        }
        label={<span style={{ fontFamily: 'OCR A Extended' }}>{device.name}</span>} // Aplica a fonte ao label
        sx={{ fontFamily: 'OCR A Extended' }} // Aplica a fonte ao FormControlLabel
      />
    ))}
  </FormGroup>
</div>

</Grid>


<Grid item xs={6}>
  <Typography variant="subtitle1" gutterBottom sx={{ fontFamily: 'OCR A Extended' }}>
    Métricas
  </Typography>
  <FormGroup sx={{ fontFamily: 'OCR A Extended' }}>
    {[
      'bateria',
      'dendrometro',
      'fluxo_seiva_1',
      'fluxo_seiva_2',
      'sensor_reserva',
      'todas as métricas'
    ].map(property => (
      <FormControlLabel
        key={property}
        control={
          <Checkbox
            checked={selectedProperties[property]}
            onChange={() => handleToggleProperty(property)}
            sx={{ '&.Mui-checked': { color: '#033b57' } }} // Opcional: altera a cor do checkbox selecionado
          />
        }
        label={<span style={{ fontFamily: 'OCR A Extended' }}>{property === 'todas as métricas' ? 'Todos' : property}</span>} // Aplica a fonte ao label
        sx={{ fontFamily: 'OCR A Extended' }} // Aplica a fonte ao FormControlLabel
      />
    ))}
  </FormGroup>
</Grid>

            </Grid>

            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontFamily: 'OCR A Extended' }}>
  Formato
</Typography>
<RadioGroup
  value={fileType}
  onChange={(e) => setFileType(e.target.value)}
  row
  sx={{ fontFamily: 'OCR A Extended' }}
>
  <FormControlLabel 
    value="csv" 
    control={<Radio />} 
    label={<span style={{ fontFamily: 'OCR A Extended' }}>CSV</span>} 
    sx={{ fontFamily: 'OCR A Extended' }} 
  />
  <FormControlLabel 
    value="txt" 
    control={<Radio />} 
    label={<span style={{ fontFamily: 'OCR A Extended' }}>TXT</span>} 
    sx={{ fontFamily: 'OCR A Extended' }} 
  />
  <FormControlLabel 
    value="pdf" 
    control={<Radio />} 
    label={<span style={{ fontFamily: 'OCR A Extended' }}>PDF</span>} 
    sx={{ fontFamily: 'OCR A Extended' }} 
  />
</RadioGroup>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button 
                  variant='contained' 
                  fullWidth onClick={handleDownload} 
                  startIcon={<Download/>} 
                  sx={{
                    backgroundColor:'#033b57', 
                    color:'#fff', 
                    height:55,
                    fontFamily: 'OCR A Extended'
                    }} 
                  >
                  Download 
                </Button>
              </Grid>
              
            </Grid>
          </Box>
        </Modal>
      </Container>

      <Rodape />
    </>
  );
};

export default Dados;

    
    */ }