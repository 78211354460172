import React, { useState } from 'react';
import { Alert, Container, Snackbar, TextField, Button } from '@mui/material';
import { Lock, LoginRounded, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../Assets/logo-2.png';
import { enviroments } from '../../enviroments/enviroments';

const Login = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('success');

  const enviarDados = async (e) => {
    e.preventDefault(); 
    try {
      if (!nome || !senha) {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Usuário e/ou senha inválidos!');
        return;
      }

      const response = await axios.post(`${enviroments.api_backend}/usuarios/login`, {
        nome: nome,
        senha: senha
      });

      const token = response.data.token;
      localStorage.setItem('token', token);
      navigate('/concentrador');
    } catch (error) {
      if (error.response && (error.response.status === 404 || error.response.status === 401)) {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Usuário e/ou senha inválidos!');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Erro no Servidor!');
      }
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert 
          variant='filled' 
          onClose={() => setAlerta(false)} 
          severity={tipoAlerta}
          sx={{fontFamily:'OCR A Extended'}}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>
      <Container
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: 350,
        }}
      >
        <form onSubmit={enviarDados} style={{ width: '100%' }}>
          <img
            src={logo} 
            alt="Suzano"
            style={{ height: 'auto', width: '100%' }}
          />
          <TextField
            label='Usuário'
            type='text'
            placeholder='Digite o usuário'
            fullWidth
            onChange={(e) => setNome(e.target.value)}
            InputProps={{
              startAdornment: (
                <Person sx={{ color: 'action.active', mr: 1 }} />
              ),
              sx: { fontFamily: 'OCR A Extended' }, 
            }}
            InputLabelProps={{
              sx: { fontFamily: 'OCR A Extended' } 
            }}
            sx={{ mb: 2, mt: 4 }}
          />
          <TextField
            label='Senha'
            type='password'
            placeholder='Digite a senha'
            fullWidth
            onChange={(e) => setSenha(e.target.value)}
            InputProps={{
              startAdornment: (
                <Lock sx={{ color: 'action.active', mr: 1 }} />
              ),
              sx: { fontFamily: 'OCR A Extended' }, 
            }}
            InputLabelProps={{
              sx: { fontFamily: 'OCR A Extended' } 
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant='contained'
            fullWidth
            startIcon={<LoginRounded />}
            sx={{ mb: 2, backgroundColor: '#033b57', height: '55px', fontFamily: 'OCR A Extended' }}
            type="submit"
          >
            Login
          </Button>
        </form>
      </Container>
    </>
  );
};

export default Login;



{/**
  import React, { useState } from 'react';
import { Alert, Box, Button, Card, CardContent, CardMedia, Snackbar, TextField, Typography } from '@mui/material';
import { Email, Lock, LoginRounded, AppRegistration, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../Assets/SZN.png';
import { enviroments } from '../../enviroments/enviroments';


const Login = () => {
  const navigate = useNavigate();

  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('success');

  const enviarDados = async () => {
    try {
      if (!nome || !senha) {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Insira Email e a Senha');
        return;
      }
          
      const response = await axios.post(`${enviroments.api_backend}/usuarios/login`, {
        nome: nome,
        senha: senha
      });
  
      const token = response.data.token;
      localStorage.setItem('token', token);
  
      navigate('/visao-geral');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Usuário não encontrado.');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else if (error.response && error.response.status === 401) {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Usuário ou senha inválido');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Erro no Servidor!');
      }
    }
  };

  const IrParaCadastro = () => {
    navigate('/cadastro-usuario');
  };

  const recuperarSenha = () => {
    navigate('/recuperar-senha');
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => { }}
      >
        <Alert variant='filled' onClose={() => { setAlerta(false) }} severity={tipoAlerta}>
          {mensagemAlerta}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Card sx={{ maxWidth: 322 }}>
          <CardMedia
            component="img"
            sx={{ height: 185}}
            image={logo}
            alt="Suzano"
          />

          <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
            <Typography
              sx={{
                fontSize: 25,
                fontFamily: 'Outfit',
                fontWeight: 'bold',
                color: '#03267f',
              }}
            >
              IoTree
            </Typography>

            <TextField
              label='Usuário'
              type='text'
              required
              sx={{
                mb: 2,
                mt: 2,
              }}
              fullWidth
              onChange={(e) => {
                setNome(e.target.value);
              }}
              helperText={nome === '' ? 'Este campo é obrigatório' : ''}
              InputProps={{
              startAdornment: (
                <Person sx={{ color: 'action.active', mr: 1 }}/>
                
              ),
              }}
            />

            <TextField
              label='Senha'
              type='password'
              required
              sx={{
                mb: 2,
              }}
              fullWidth
              onChange={(e) => {
                setSenha(e.target.value);
              }}
              helperText={senha === '' ? 'Este campo é obrigatório' : ''}
              InputProps={{
                startAdornment:(
                  <Lock sx={{color: 'action.active', mr:1}}/>
                )
              }}
            />

            <Button
              variant='contained'
              fullWidth
              startIcon={<LoginRounded/>}
              sx={{
                mb: 2,
                backgroundColor: '#13279a', //#00cc60
              }}
              onClick={enviarDados}
            >
              Login
            </Button>

         
       
         </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Login;
  */}
